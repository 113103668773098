import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Box } from '@material-ui/core';

ReactDOM.render(
  // <React.StrictMode>
  <>
    <Box display={{ xs: 'block', md: 'none' }}>
      <App />
    </Box>
    <Box display={{ xs: 'none', md: 'block' }} textAlign="center" margin={10}>
      <h1>الموقع يدعم اصدار الجوال فقط</h1>
    </Box>
  </>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
