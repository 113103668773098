import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useStyles } from "theme/lasik";
import {
  Badge,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Logo from "app/components/logo";
import { useHistory } from "react-router";
import PostAddIcon from "@material-ui/icons/PostAdd";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import ContactsIcon from "@material-ui/icons/Contacts";

const menu_data = [
  { text: "حجز موعد", url: "/home/reservation", icon: <PostAddIcon /> },
  { text: "الموقع", url: "/home/location", icon: <NotListedLocationIcon /> },
  { text: "تواصل معنا", url: "/home", icon: <ContactsIcon /> },
];
export default function ButtonAppBar() {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      // className={clsx(classes.list, {
      //   [classes.fullList]: anchor === "top" || anchor === "bottom",
      // })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menu_data.map((menu, index) => (
          <ListItem button key={index} onClick={() => history.push(menu.url)}>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer("left", true)}
        >
          <MenuIcon />
        </IconButton>
        {/* <Avatar style={{ marginLeft: 15 }}> */}
        <Logo width={40} height={40} style={{ marginLeft: 15 }} />
        {/* </Avatar> */}
        <Typography variant="h6" className={classes.title}>
          أ.د.عبدالرحمن العمري
        </Typography>

        <Badge badgeContent={0} color="secondary">
          <IconButton
            aria-label="delete"
            className={classes.margin}
            size="small"
            // style={{ color: "white" }}
          >
            <AccountCircle />
          </IconButton>
        </Badge>
      </Toolbar>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </AppBar>
  );
}
