import React from "react";
import logo from "assets/logo-02.png";
import "assets/css/logoshine.css";
import { Zoom } from "@material-ui/core";

export default function Logo({ width = 97, height = 88, ...prop }) {
  return (
    <div {...prop}>
      <Zoom in={true}>
        <div
          style={{
            position: "relative",
            maxWidth: 97,
            maxHeight: 88,
            width: width,
            height: height,
            margin: "0 auto",
          }}
        >
          <div id="logo" style={{ width: width, height: height }}>
            <img alt="أ.د.عبدالرحمن العمري" src={logo} />
          </div>
        </div>
      </Zoom>
    </div>
  );
}
