import React from "react";
import SlideLoading from "app/Starter/SlideLoading";
import { useEffect } from "react";

export default function Starter({ setShowAppBar }) {
  useEffect(() => {
    setShowAppBar(false);
    return () => {
      setShowAppBar(true);
    };
  }, [setShowAppBar]);
  // const [loadingSlide, setLoadingSlide] = useState(true);
  return <SlideLoading />;
}
