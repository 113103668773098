import {
  Button,
  Divider,
  LinearProgress,
  Slide,
  Typography,
  Zoom,
} from "@material-ui/core";
import Logo from "app/components/logo";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import loading02 from "assets/lottie/loading02.json";
import { useRef } from "react";
import { useHistory } from "react-router";

export default function SlideLoading() {
  // const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  // const handleExitingWindow = () => {
  //   // onEndLoading(false);

  //   console.log("exited");
  // };
  const [progress, setProgress] = useState(0);
  const player = useRef();
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress < 100) {
          const diff = Math.random() * 1;
          const num = Math.min(oldProgress + diff, 100);
          player.current.setSeeker(num.toFixed(2) - 15);

          // player.current.setSeeker(`${String(num.toFixed(0))}%`, false);

          // console.log(num.toFixed(0));
          return num;
        } else {
          setLoading(false);
          clearInterval();
        }
      });
    }, 15);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // const handleCloseSlide = () => {
  //   console.log("handleCLoseSlide");
  //   setOpen(false);
  // };

  return (
    <Slide
      // onExited={handleExitingWindow}
      direction="down"
      in={true}
      style={{ backgroundColor: "aliceblue" }}
      mountOnEnter
      unmountOnExit
    >
      <div
        dir="rtl"
        // className="d-flex flex-column align-items-center justify-content-center vh-100"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Logo />
        <div style={{ width: "100%", textAlign: "center", padding: 50 }}>
          {loading && (
            <>
              <Player
                ref={player}
                // autoplay={true}
                src={loading02}
                style={{ height: "300px", width: "300px" }}
              />
              <Typography variant="body1">تحميل</Typography>
              <Divider style={{ marginTop: 20 }} />
              <LinearProgress variant="determinate" value={progress} />
            </>
          )}
          {!loading && (
            <>
              <Zoom in={true}>
                <Typography variant="h6" color="secondary">
                  موقع
                </Typography>
              </Zoom>
              <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                <Typography variant="h4">أ.د.عبدالرحمن العمري </Typography>
              </Zoom>
              <Divider style={{ marginBottom: 20 }} />
              <Zoom in={true} style={{ transitionDelay: "200ms" }}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  // onClick={handleCloseSlide}
                  onClick={() => history.push("home")}
                >
                  دخول
                </Button>
              </Zoom>
            </>
          )}
        </div>
      </div>
    </Slide>
  );
}
