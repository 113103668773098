import { Divider, Typography } from "@material-ui/core";
import React from "react";

export default function Apointment() {
  return (
    <>
      <Typography variant="h4" style={{ textAlign: "center" }}>
        حجز موعد
      </Typography>
      <Divider />
      <Typography variant="body1" style={{ textAlign: "center" }}>
        الرجاء تعبة الفورم ادناه ببيانات صحيحة
      </Typography>
    </>
  );
}
