import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Dialog, Grid, MenuItem } from "@material-ui/core";
import { useState } from "react";
import { useStyles } from "theme/lasik";
import { Player } from "@lottiefiles/react-lottie-player";
import loading01 from "assets/lottie/loading01.json";
import check from "assets/lottie/check.json";
import axios from "axios";
import { ENDPOINT } from "setting";
import { useRef } from "react";
import Apointment from "./apointment";

export default function ReservationForm({ closeReservation }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingCom, setLoadingCom] = useState(false);
  const [gender, setGender] = useState("");
  const [props, setProps] = useState("");
  // const [complete, setComplete] = useState({ load: false, complete: false });
  const readyToSend = useRef(false);

  const handleFinishAnimationLoading = (event) => {
    if (event === "loop") {
      // console.log("loop");
      // setComplete((s) => ({ load: true, ...s }));
      // console.log(readyToSend.current);
      if (readyToSend.current === true) {
        const handleSuccessSubmit = (event) => {
          if (event === "complete") {
            setLoading(false);
            closeReservation();
          }
        };
        setLoadingCom(
          <Player
            onEvent={handleSuccessSubmit}
            autoplay="1"
            play="1"
            controls={true}
            src={check}
            style={{ height: "300px", width: "300px" }}
          />
        );
      }
    }
  };
  const handleSubmitReservation = async (e) => {
    setLoading(true);
    setLoadingCom(
      <Player
        onEvent={handleFinishAnimationLoading}
        autoplay={true}
        // play="1"
        // onEvent={(event) => {
        //   if (event === "complete") setComplete((s) => ({ load: true, ...s })); // check event type and do something
        // }}
        loop={true}
        controls={false}
        src={loading01}
        style={{ height: "300px", width: "300px" }}
      />
    );

    e.preventDefault();
    const data = {
      fname: e.target.fname.value,
      gender,
      age: e.target.age.value,
      email: e.target.email.value,
      phone_number: e.target.phone_number.value,
      props,
      civil_registry: e.target.civil_registry.value,
      content: e.target.content.value,
    };
    try {
      await axios.post(`${ENDPOINT}/reservations`, data);
      // setComplete((s) => ({ complete: true, ...s }));
      readyToSend.current = true;
    } catch (error) {}
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handlePropsChange = (event) => {
    setProps(event.target.value);
  };

  return (
    <>
      <Dialog
        fullScreen
        className={classes.backdrop}
        open={loading}
        style={{
          backgroundColor: "#ffffff",
          zIndex: 10000,
          // paddingBottom: 100,
          // justifyContent: "center",
          // alignItems: "center",
          // display: "flex",
        }}
        // onClick={handleClose}
      >
        <div style={{ marginTop: 100 }}>{loadingCom}</div>
      </Dialog>

      <form
        className={classes.root}
        // noValidate
        autoComplete="off"
        onSubmit={handleSubmitReservation}
      >
        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
          >
            <Apointment />
            <TextField
              //   error
              required
              id="fname"
              label="الاسم كاملاً"
              variant="outlined"
              // value="test"
            />
            <TextField
              // required
              id="gender"
              select
              label="الجنس"
              onChange={handleGenderChange}
              value={gender}
              variant="outlined"
            >
              <MenuItem value="">اختيار الجنس</MenuItem>
              <MenuItem value="male">ذكر</MenuItem>
              <MenuItem value="female">انثى</MenuItem>
            </TextField>
            <TextField
              //   error
              required
              id="age"
              label="العمر"
              variant="outlined"
            />
            <TextField
              //   error
              id="email"
              type="email"
              label="البريد الالكتروني"
              variant="outlined"
            />
            <TextField
              //   error
              id="phone_number"
              label="رقم الجوال"
              variant="outlined"
            />
            <TextField
              required
              id="props"
              select
              onChange={handlePropsChange}
              value={props}
              label="الاستشارة المطلوبة"
              variant="outlined"
            >
              <MenuItem value="">اختيار الاستشارة</MenuItem>
              <MenuItem value="تصحيح الابصار">تصحيح الابصار</MenuItem>
              <MenuItem value="مياه بيضاء">مياه بيضاء</MenuItem>
              <MenuItem value="القرنية المخروطية">القرنية المخروطية</MenuItem>
              <MenuItem value="اخرى">اخرى</MenuItem>
            </TextField>
            <TextField
              //   error
              id="civil_registry"
              label="السجل المدني"
              variant="outlined"
            />
            <TextField
              id="content"
              label="شرح الحالة"
              multiline
              rows={4}
              variant="outlined"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
            >
              إرسال
            </Button>
          </Grid>
        </div>
      </form>
    </>
  );
}
