import React from "react";
import BoxInfo from "app/header/BoxInfo";
// import logo from "assets/logo-02.png";
// import { useStyles } from "../theme/lasik";
import { useStyles } from "theme/lasik";
export default function Home() {
  const classes = useStyles();

  return (
    <div
      dir="rtl"
      // className="d-flex align-items-start justify-content-center text-white-50 vh-100"
      style={
        {
          // background: "url(assets/images/bg.png)",
        }
      }
    >
      <div className="w-100">
        {/* <Home /> */}
        {/* <Landing /> */}
        {/* <AppBar /> */}
        <div className={classes.root}>
          <BoxInfo />

          {/* <img src={logo} alt="أ.د.عبدالرحمن العمري" /> */}
        </div>
      </div>
    </div>
  );
}
