import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Zoom,
} from "@material-ui/core";
import FontAwesome from "app/common/FontAwesome";
import Logo from "app/components/logo";
import React from "react";
import { useStyles } from "theme/lasik";
import {
  faMedkit,
  faAward,
  faEye,
  faScroll,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useHistory } from "react-router";
import doctor from "assets/images/doctor.png";
import snapchat from "assets/images/snapchatalamri.jpeg";
import {
  faSnapchat,
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import PostAddIcon from "@material-ui/icons/PostAdd";

const textArr = [
  {
    txt: "نشر أبحاث في مجلات إقليمية وعالمية",
    icon: faScroll,
  },
  {
    txt: "شارك في تطوير أدوية وتقنيات طب العيون",
    icon: faMedkit,
  },
  {
    txt: "حاز على جوائز محلية و دولية",
    icon: faAward,
  },
  {
    txt: "أجرى آلاف عمليات العيون بنجاح",
    icon: faEye,
  },
];

export default function BoxInfo() {
  const classes = useStyles();
  // const [zoomIn, setZoomIn] = useState(true);
  // const [reservationOpen, setReservationOpen] = useState(false);
  const [dialog, setDialog] = useState(false);
  const history = useHistory();
  const handleSocUrl = (url) => {
    window.open(url);
    // window.location.href = url;
  };
  const handleCloseSnapChatLog = () => {
    setDialog(false);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Logo />
      </Grid>
      <Grid item xs={12} sm={12}>
        <div>
          <Grid container spacing={2}>
            <Zoom
              in={true}
              style={{ transitionDelay: "200ms", textAlign: "center" }}
            >
              <Grid item xs={4}>
                <Paper elevation={5}>
                  <img
                    src={doctor}
                    style={{ width: "100%" }}
                    alt="أ.د.عبدالرحمن العمري"
                  />
                </Paper>
              </Grid>
            </Zoom>
            <Grid
              item
              xs={8}
              container
              direction="column"
              justify="space-around"
              alignItems="flex-start"
            >
              <Zoom
                in={true}
                style={{ transitionDelay: "250ms", textAlign: "center" }}
              >
                <Typography color="textPrimary" variant="h5">
                  أ.د.عبدالرحمن العمري
                </Typography>
              </Zoom>
              <Zoom
                in={true}
                style={{ transitionDelay: "200ms", textAlign: "center" }}
              >
                <Typography
                  variant="h5"
                  style={{
                    backgroundColor: "ActiveCaption",
                    fontWeight: "bold",
                  }}
                  className={classes.paper}
                >
                  بروفيسور واستشاري
                </Typography>
              </Zoom>
              <Zoom
                in={true}
                style={{ transitionDelay: "200ms", textAlign: "center" }}
              >
                <Typography color="textPrimary" variant="h5">
                  طب وجراحة العيون
                </Typography>
              </Zoom>
              <Zoom
                in={true}
                style={{ transitionDelay: "300ms", textAlign: "center" }}
              >
                <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="center"
                >
                  <IconButton
                    color="primary"
                    onClick={() => handleSocUrl("https://twitter.com/dramri")}
                  >
                    {/* <TwitterIcon /> */}
                    <FontAwesome icon={faTwitter} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      handleSocUrl("https://www.instagram.com/dralamri/")
                    }
                  >
                    {/* <InstagramIcon /> */}
                    <FontAwesome icon={faInstagram} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      handleSocUrl("https://www.facebook.com/Dramri")
                    }
                  >
                    {/* <FacebookIcon /> */}
                    <FontAwesome icon={faFacebook} />
                  </IconButton>
                  <IconButton color="primary" onClick={() => setDialog(true)}>
                    <FontAwesome icon={faSnapchat} />
                  </IconButton>
                </Grid>
              </Zoom>
            </Grid>
          </Grid>
        </div>
        <Dialog
          onClose={handleCloseSnapChatLog}
          aria-labelledby="simple-dialog-title"
          open={dialog}
        >
          <img src={snapchat} alt="SnapChat" />
        </Dialog>
      </Grid>
      <Grid item xs={12} sm={12}>
        {/* <Paper style={{ padding: 20 }}> */}
        <Grid container spacing={3}>
          <List style={{ width: "100%" }}>
            {textArr.map((obj, index) => (
              <div key={index}>
                <Zoom
                  in={true}
                  style={{
                    transitionDelay: `${100 * index + 300}ms`,
                    textAlign: "right",
                  }}
                >
                  <ListItem button>
                    <ListItemIcon style={{ justifyContent: "center" }}>
                      <FontAwesome
                        color="darkblue"
                        icon={obj.icon}
                        style={{ marginLeft: 10 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{ textAlign: "right" }}
                      primary={obj.txt}
                    />
                  </ListItem>
                </Zoom>
                <Divider />
              </div>

              // <Grid key={index} item xs={12} sm={3}>
              //   <Zoom
              //     in={zoomIn}
              //     style={{
              //       transitionDelay: `${100 * index + 300}ms`,
              //       textAlign: "right",
              //     }}
              //   >
              //     <Typography color="textPrimary" variant="body1">
              //         <FontAwesome
              //           color="darkblue"
              //           icon={obj.icon}
              //           style={{ marginLeft: 10 }}
              //         />
              //       {obj.txt}
              //     </Typography>
              //   </Zoom>
              // </Grid>
            ))}
          </List>
        </Grid>
        {/* </Paper> */}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Paper elevation={1} className={classes.gradient}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: "100px" }}
            onClick={() => history.push("/home/reservation")}
          >
            <Grid item xs={8}>
              <Typography
                style={{ textAlign: "center" }}
                color="inherit"
                variant="h5"
              >
                احجز موعدك الان
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => history.push("home/reservation")}
                style={{ padding: 20, paddingRight: 0 }}
                fullWidth
                startIcon={<PostAddIcon style={{ marginLeft: 10 }} />}
              >
                حجز
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
