import { Button, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

export default function Location() {
  const history = useHistory();
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <div
          style={{
            padding: 10,
            textAlign: "center",
          }}
        >
          <Typography variant="h2">موقعنا</Typography>
          <Typography variant="subtitle1">ابها - مستشفيات مغربي</Typography>
          <Button variant="contained" onClick={() => history.push("/home")}>
            العودة
          </Button>
        </div>
        <iframe
          title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d78607.37677003765!2d42.66176465690409!3d18.28280627701159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15fb57aae97dc76b%3A0x6752c2a19614ba4f!2sMagrabi+Hospital!5e0!3m2!1sen!2s!4v1505301530655"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ alignItems: "stretch", flexGrow: 1 }}
          //   style="border:0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
