import { makeStyles, createMuiTheme } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
// import { createMuiTheme } from '@material-ui/core/styles';
export const theme = createMuiTheme({
  direction: 'rtl',
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": '"Tajawal"',
      },

    }, MuiPaper: {
      root: {
        background: "#f5f5f5",
        backgroundColor: "none",
      }
    }
  },
  palette: {
    primary: {
      // light: "linear-gradient(45deg, #d645ad, #1e97e9)",
      light: "#fff",
      main: "#06437F",
      dark: "#1e1e1f",
    },
    secondary: {
      light: "#fff5f8",
      main: "#21B1D8",
      dark: "#e62958",
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    },
    background: {
      default: "#fff",
      // paper: `#f5f5f5`,
      primary: `linear-gradient(45deg, #d645ad, #1e97e9);`,
      // primary: `linear-gradient(45deg, #06437F 2.99%, #09518C 30%, #1170A5 50%,  #1A98C4 69.76%, #21B1D8 100% );`,
      // paper: "#06437F"
    },

  },
  typography: {
    direction: "rtl",
    fontFamily: [
      '"Tajawal"',
    ].join(","),
  },
});

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    "& .MuiTextField-root": {
      // marginRight: theme.spacing(0),
      // marginLeft: theme.spacing(0),
      marginTop: theme.spacing(2),
      //   width: 200,
    },

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  appBar: {
    backgroundColor: "#ffffff",
    color: "#1e1e1f"
  },
  gradient: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: '#cedaea',
    background: theme.palette.background.primary,
  },
  menuButton: {
    marginLeft: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    textAlign: "right",
  },
  fullHeight: {
    // height: "100vh",
    // display: "flex",
    height: "100%",
    flex: 1,
  },

}));

// export { useStyles }
